import {Outlet} from "react-router-dom";
import Menu from "./components/Menu";
import React from "react";
import MusicPlayer from "./components/MusicPlayer";

function Layout() {
    return (
        <>
            <header>
                <h1>Hackvent 2023</h1>
            </header>
            <div id="monitor">
                <div id="screen">
                    <Outlet />
                </div>
                <div id="underScreen">
                    <MusicPlayer />
                    <Menu />
                </div>
            </div>
        </>
    );
}

export default Layout;