import React, {useContext} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Layout from "./Layout";
import Login from "./account/pages/Login";
import Register from "./account/pages/Register";
import ErrorPage from "./tech/pages/error-page";
import Assignments, {loader as assignmentsLoader} from "./assignment/pages/Assignments";
import Assignment, {loader as assignmentLoader} from "./assignment/pages/Assignment";
import Profile, {loader as profileLoader} from "./account/pages/Profile";
import UserContext from "./account/UserContext";
import HighScores, {loader as highScoresLoader} from "./highscores/pages/HighScores";
import Home from "./content/pages/home";
import HighScores2022 from "./highscores/pages/HighScores2022";

function App() {
    const user = useContext(UserContext);
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            errorElement: <ErrorPage/>,
            children: [
                {
                    errorElement: <ErrorPage/>,
                    children: [
                        {
                            path: "",
                            element: <Home />
                        },
                        {
                            path: "zadania",
                            element: <Assignments/>,
                            loader: async () => assignmentsLoader(user)
                        },
                        {
                            path: "zadania/:assignmentId",
                            element: <Assignment/>,
                            loader: async ({params}) => assignmentLoader(params, user)
                        },
                        {
                            path: "wyniki",
                            element: <HighScores/>,
                            loader: highScoresLoader
                        },
                        {
                            path: "wyniki-2022",
                            element: <HighScores2022/>
                        },
                        {
                            path: "logowanie",
                            element: <Login/>
                        },
                        {
                            path: "rejestracja",
                            element: <Register/>
                        },
                        {
                            path: "profil",
                            element: <Profile/>,
                            loader: async () => profileLoader(user)
                        }
                    ]
                }
            ],
        },
    ])

    return (
        <RouterProvider router={router}/>
    );
}

export default App;
