import {useContext, useState} from "react";
import FromInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import {NavLink} from "react-router-dom";
import UserContext from "../UserContext";

function Register() {
    const [isLoading, setIsLoading] = useState(false);
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [createdAccount, setCreatedAccount] = useState(false);
    const [errorDetails, setErrorDetails] = useState([]);
    const user = useContext(UserContext);

    const createAccount = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorDetails([]);
        const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/auth/account`, {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                nickname, email
            })
        });
        if (response.status === 400) {
            const errorDetails = (await response.json()).errors;
            setErrorDetails(errorDetails);
        } else if (response.status !== 201) {
            throw Error(`Registration has failed with ${response.status} code, body: ${await response.text()}`);
        } else {
            const responseBody = await response.json();
            setCreatedAccount(responseBody);
            user.login(responseBody.nickname, responseBody.token);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Loader isLoading={isLoading}/>
            {!isLoading && !createdAccount && <>
                <h2>Rejestracja</h2>
                <p>Założenie konta umożliwia śledzenie postępu i zbieranie punktów za rozwiązane zadania.</p>
                <p>Na podany adres e-mail wyślemy kopię danych dostępowych do platformy, podanie adresu e-mail jest nieobowiązkowe, adres e-mail jest wykorzystywany wyłacznie na potrzeby wysłania maila z dostępem i nie jest przechowywany.</p>
                <p className="error">{!Array.isArray(errorDetails) && errorDetails}</p>
                <form onSubmit={createAccount} autoComplete="off">
                    <FromInput name="nickname" label="Pseudonim" placeholder="Merroshi Christmasmoto" getter={nickname}
                               setter={setNickname} errors={errorDetails}/><br/>
                    <FromInput name="email" type="email" label="E-mail" placeholder="elf@red.team" getter={email} setter={setEmail}
                               errors={errorDetails}/><br/>
                    <input type="submit" value="Utwórz konto"/>
                </form>

                <p>Masz już konto? <NavLink to={"/logowanie"}>Zaloguj się</NavLink></p>
            </>}
            {createdAccount && <>
                <h1>{createdAccount.nickname}, twoje konto zostało zarejestrowane!</h1>
                <p>
                    Twoje userId: <code>{createdAccount.userId}</code>
                </p>
                <p>UserId jest wykorzystywane przy logowaniu, zachowaj je aby nie stracić dostępu do swoich wyników!
                    {createdAccount.isEmailSent && <> Na Twój email ({email}) wysłaliśmy wiadomość w której znajdziesz UserId.</>}
                </p>
            </>}
        </>
    );
}

export default Register;
