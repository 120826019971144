export default function HighScores2022() {
    const highScores = [{"points": 240, "nickname": "prgmr"}, {"points": 240, "nickname": "fsgeek"}, {
        "points": 240,
        "nickname": "ProudTurtle"
    }, {"points": 240, "nickname": "JanNiezbedny"}, {"points": 240, "nickname": "GangzAlbanii"}, {
        "points": 240,
        "nickname": "sendivogius"
    }, {"points": 230, "nickname": "Chomer"}, {"points": 225, "nickname": "marcinzemlok"}, {
        "points": 225,
        "nickname": "margoluz"
    }, {"points": 225, "nickname": "agroprogramista"}, {
        "points": 220,
        "nickname": "MACKOOOOOOOOOOOOOOOOOOOOOOOOOOOO"
    }, {"points": 210, "nickname": "stefix"}, {"points": 210, "nickname": "emilka"}, {
        "points": 205,
        "nickname": "seppa89"
    }, {"points": 200, "nickname": "Krzyzu"}, {"points": 200, "nickname": "Yanek"}, {
        "points": 195,
        "nickname": "Goblin"
    }, {"points": 190, "nickname": "Franz"}, {"points": 190, "nickname": "Karol"}, {
        "points": 190,
        "nickname": "walker93"
    }, {"points": 190, "nickname": "stan"}, {"points": 180, "nickname": "PhilDoCode"}, {
        "points": 175,
        "nickname": "mok3"
    }, {"points": 170, "nickname": "yahro"}, {"points": 170, "nickname": "pawelwojcik"}, {
        "points": 165,
        "nickname": "Kebab"
    }, {"points": 155, "nickname": "LisiaInformatyka"}, {"points": 155, "nickname": "michel"}, {
        "points": 150,
        "nickname": "Julitt"
    }, {"points": 150, "nickname": "NorBat"}, {"points": 150, "nickname": "pkw"}, {
        "points": 140,
        "nickname": "ada"
    }, {"points": 140, "nickname": "typeofweb"}, {"points": 140, "nickname": "Filip"}, {
        "points": 140,
        "nickname": "Pawson"
    }, {"points": 135, "nickname": "Magdalena"}, {"points": 135, "nickname": "Shaula"}, {
        "points": 135,
        "nickname": "paryzfilip"
    }, {"points": 135, "nickname": "staral"}, {"points": 130, "nickname": "SantaGaming"}, {
        "points": 130,
        "nickname": "deszrama"
    }, {"points": 130, "nickname": "vexayy"}, {"points": 130, "nickname": "Radosgra"}, {
        "points": 125,
        "nickname": "rafixqwerty"
    }, {"points": 125, "nickname": "mafineeek"}, {"points": 125, "nickname": "MK092"}, {
        "points": 125,
        "nickname": "ElfkAA"
    }, {"points": 125, "nickname": "Najlek"}, {"points": 120, "nickname": "Drukus"}, {
        "points": 120,
        "nickname": "lospedros7"
    }, {"points": 120, "nickname": "check"}, {"points": 120, "nickname": "MasterCzuli"}, {
        "points": 120,
        "nickname": "feris"
    }, {"points": 120, "nickname": "Kaktus2225"}, {"points": 120, "nickname": "kolegajakub"}, {
        "points": 120,
        "nickname": "rudytako"
    }, {"points": 120, "nickname": "gregorius"}, {"points": 120, "nickname": "hakierandrzej"}, {
        "points": 120,
        "nickname": "GryPodRozne"
    }, {"points": 120, "nickname": "PaFoStreJa"}, {"points": 120, "nickname": "GallenikZloty"}, {
        "points": 120,
        "nickname": "Yorqrett"
    }, {"points": 115, "nickname": "JTK"}, {"points": 115, "nickname": "Aga"}, {
        "points": 115,
        "nickname": "Szalon"
    }, {"points": 110, "nickname": "Lahcim"}, {"points": 110, "nickname": "przemol11"}, {
        "points": 110,
        "nickname": "Koki"
    }, {"points": 110, "nickname": "Hasanoes"}, {"points": 110, "nickname": "sk1lek"}, {
        "points": 105,
        "nickname": "Hunterr"
    }, {"points": 100, "nickname": "NW2137"}, {"points": 100, "nickname": "Subuc"}, {
        "points": 95,
        "nickname": "Adixon"
    }, {"points": 95, "nickname": "zeus51"}, {"points": 95, "nickname": "timuslala"}, {
        "points": 95,
        "nickname": "WojtekWoj"
    }, {"points": 90, "nickname": "golferjoe"}, {"points": 90, "nickname": "Tonger"}, {
        "points": 90,
        "nickname": "xznc"
    }, {"points": 90, "nickname": "plasticbag"}, {"points": 85, "nickname": "Jokabeyla"}, {
        "points": 85,
        "nickname": "aixen96"
    }, {"points": 85, "nickname": "MaciekMistrz"}, {"points": 85, "nickname": "EndocryneIndex"}, {
        "points": 85,
        "nickname": "tobi"
    }, {"points": 85, "nickname": "Ryjek"}, {"points": 80, "nickname": "structptr"}, {
        "points": 80,
        "nickname": "kotel"
    }, {"points": 80, "nickname": "IPlastus"}, {"points": 80, "nickname": "Czosnek"}, {
        "points": 80,
        "nickname": "Susel"
    }, {"points": 80, "nickname": "mats"}, {"points": 75, "nickname": "BestPlay"}, {
        "points": 75,
        "nickname": "jadwig"
    }, {"points": 75, "nickname": "neonek302"}, {"points": 75, "nickname": "kropla"}, {
        "points": 75,
        "nickname": "Ozeppo"
    }, {"points": 75, "nickname": "TheVersusxPro"}, {"points": 75, "nickname": "Norbajk"}];

    return (
        <>
            <h2>Wyniki 2022</h2>
            {!highScores.length && <p>Lista jest pusta</p>}
            <p>Ranking uwzględnia punkty zdobyte między 01.12.2022 a 31.12.2022.</p>
            <ol>
                {
                    highScores.map((score) =>
                        <li key={score.nickname}>
                            {score.points} | {score.nickname}
                        </li>)
                }
            </ol>
        </>
    )
}