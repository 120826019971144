import {Link, useLoaderData} from "react-router-dom";

export async function loader(user) {
    const headers = {};
    if (user.token) {
        headers["Authorization"] = `Bearer ${user.token}`;
    }
    const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/assignment`, {
        headers: headers
    });
    if (response.status !== 200) {
        throw Error(`Getting assignments list has failed with ${response.status} code, body: ${await response.text()}`);
    }
    return await response.json()
}

export default function Assignments() {
    const assignments = useLoaderData();

    return (
        <>
            <h2>Lista zadań</h2>
            {assignments?.availableAssignments?.length === 0 && "Pierwsze zadanie pojawi się 01.12.2023 o godzinie 00:00:00"}
            <ul>
                {
                    assignments.availableAssignments.map((assignment) =>
                        <li key={assignment.id}>
                            <Link to={`/zadania/${assignment.id}`}>{assignment.name}</Link> {assignment.isSolved && "[flaga zdobyta]"}
                        </li>)
                }
            </ul>
        </>
    )
}