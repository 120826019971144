export default function FromInput({name, label, placeholder, getter, setter, errors, type}) {
    const errorMappings = {
        "InvalidFlagFormat": "Nieprawidłowy format flagi, wymagany HCKVNT_{...}",
        "Empty": "Pole jest wymagane",
        "TooLong": "Za długa wartość",
        "InvalidCharacters": "Nieobsługiwane znaki",
        "InvalidEmail": "Nieprawidłowy e-mail",
        "InvalidUserId": "Nieprawidłowe userId",
        "NicknameTaken": "Pseudonim jest już zajęty",
        "WrongFlag": "Nieprawidłowa flaga",
    }
    const errorsForInput = (Array.isArray(errors) ? errors.filter(e => e.field.toLowerCase() === name.toLowerCase()).map(e => e.error) : []);

    return (<>
        {errorsForInput?.length > 0 &&
            <ul className="error">
                {errorsForInput.map((e) =>
                    <li key={e}>{errorMappings[e] ?? e}</li>
                )}
            </ul>
        }
        <label>{label}: </label><input type={type ?? "text"} placeholder={placeholder} value={getter} name={name}
                        onChange={e => setter(e.target.value)}/>
    </>)
}