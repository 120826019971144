import {useEffect, useRef, useState} from "react";

const tracks = ["Carol-of-the-bells.mp3", "jingle-bells-8bit.mp3", "Nullsleep-silent-night.mp3"];

export default function MusicPlayer() {
    // https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
    const [trackIndex, setTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(null);
    
    useEffect(() => {
        if (isPlaying) {
            audioRef.current?.pause();
            audioRef.current = new Audio(`/music/${tracks[trackIndex]}`);
            audioRef.current.loop = true;
            audioRef.current.play();
        } else {
            audioRef.current?.pause();            
        }
    }, [trackIndex, isPlaying]);
    
    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioRef.current?.pause();
        }
    }, []);
    
    const nextTrack = () => {
        if (trackIndex < tracks.length - 1) {
            setTrackIndex(trackIndex + 1);
        } else {
            setTrackIndex(0);
        }
    }
    
    return (
        <div className="musicPlayer buttons">
            <button className={isPlaying ? "active" : ""} onClick={() => setIsPlaying(isPlaying => !isPlaying)}>&#9658;</button>
            <button onClick={() => nextTrack()}>&#9658;&#9658;</button>
        </div>
    )
}
