import {NavLink} from "react-router-dom";
import {useContext} from "react";
import UserContext from "../account/UserContext";

export default function Menu() {
    const user = useContext(UserContext);
    return (
        <>
            <nav className="buttons">
                <NavLink end to={"/"}>Home</NavLink>
                <NavLink to={"/zadania"}>Zadania</NavLink>
                <NavLink to={"/wyniki"}>Wyniki</NavLink>
                <NavLink to={user.isLoggedIn ? "/profil" : "/logowanie"}>Profil</NavLink>
            </nav>
        </>
    )
}