import {useLoaderData} from "react-router-dom";
import SolveAssignmentForm from "../componenets/SolveAssignmentForm";
import DOMPurify from 'dompurify';

const AssignmentNotAvailable = "Forbidden";
const AssignmentNotFound = "NotFound";

export async function loader(params, user) {
    const headers = {};
    if (user.token) {
        headers["Authorization"] = `Bearer ${user.token}`;
    }
    const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/assignment/${params.assignmentId}`, {
        headers: headers
    });
    if (response.status === 403) {
        return AssignmentNotAvailable;
    }
    if (response.status === 404) {
        return AssignmentNotFound;
    }
    if (response.status !== 200) {
        throw Error(`Getting assignment has failed with ${response.status} code, body: ${await response.text()}`);
    }
    return await response.json()
}

export default function Assignment() {
    const assignment = useLoaderData();
    if (assignment === AssignmentNotAvailable) {
        return (<h1>Następne zadanie zostanie opublikowane już jutro! Nie kombinuj bo trafisz na listę niegrzecznych 😈</h1>);
    }
    if (assignment === AssignmentNotFound) {
        return (<h1>Zadanie nie istnieje. Dlaczego uważasz, że jest ich więcej niż 24?</h1>);
    }
    
    const sanitizedSummary = DOMPurify.sanitize(assignment.summary, { ALLOWED_TAGS: [ 'p', 'code', 'img', 'a', 'br' ], ALLOWED_ATTR: ['src', 'href', 'target' ]});

    function formatDate(dateString) {
        if (!dateString) return;
        try {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day =  String(date.getDate()).padStart(2, '0');
            const hour =  String(date.getHours()).padStart(2, '0');
            const minutes =  String(date.getMinutes()).padStart(2, '0');
            const seconds =  String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
        } catch (e) {
            return dateString;
        }
    }
    
    return (
        <>
            <h1>{assignment.name}</h1>
            <div dangerouslySetInnerHTML={{__html: sanitizedSummary}}></div>
            <hr/>

            {assignment.solution && <>
                <p>Flaga zdobyta {formatDate(assignment.solution.solvedOnDate)} za {assignment.solution.points} punktów</p>
                <hr/>
            </>}

            <SolveAssignmentForm assignment={assignment}/>
        </>
    )
}