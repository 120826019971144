import { useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    Sentry.captureException(error);

    return (
        <div id="error-page">
            <h1>Ojej! Wystąpił błąd.</h1>
            <p>Elfy zajmą się szukaniem przyczyn jak tylko zapakują wszystkie prezenty.</p>
        </div>
    );
}