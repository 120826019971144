import {createContext, useState} from "react";

const LocalStorageKey = "profile";

const UserContext = createContext({
    isLoggedIn: false,
    token: '',
    nickname: '',
    login: (nickname, token) => {},
    logout: () => {}
});

function parseJwt (token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
}

export const UserProvider = ({children}) => {
    let profileFromStorage = null;
    try {
        profileFromStorage = JSON.parse(localStorage.getItem(LocalStorageKey));
    } catch(e) { }
    
    const [isLoggedIn, setIsLoggedIn] = useState(!!profileFromStorage)
    const [nickname, setNickname] = useState(profileFromStorage?.nickname ?? '')
    const [token, setToken] = useState(profileFromStorage?.token ?? '')
    const login = (nickname, token) => {
        setIsLoggedIn(true);
        setNickname(nickname);
        setToken(token);
        localStorage.setItem(LocalStorageKey, JSON.stringify({ nickname, token }));
    };
    const logout = () => {
        setIsLoggedIn(false);
        setNickname('');
        setToken('');
        localStorage.removeItem(LocalStorageKey);
    };
    
    const parsedToken = parseJwt(token);
    if (parsedToken && parsedToken.exp * 1000 < Date.now()) {
        logout();
    }
    
    return (
        <UserContext.Provider value={{isLoggedIn, nickname, token, login, logout}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;