import {NavLink, useLoaderData} from "react-router-dom";

export async function loader() {
    const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/highscores`);
    if (response.status !== 200) {
        throw Error(`Getting high scores has failed with ${response.status} code, body: ${await response.text()}`);
    }
    return await response.json()
}

export default function HighScores() {
    const {highScores} = useLoaderData();

    return (
        <>
            <h2>Wyniki</h2>
            <NavLink to={"/wyniki-2022"}>Tablica wyników z edycji 2022</NavLink>
            {!highScores.length && <p>Lista jest pusta</p>}
            <p>Ranking uwzględnia punkty zdobyte między 01.12.2023 a 31.12.2023.</p>
            <ol>
                {
                    highScores.map((score) =>
                        <li key={score.nickname}>
                            {score.points} | {score.nickname}
                        </li>)
                }
            </ol>
        </>
    )
}