import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from "./Root";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://7881946c3db64b6d90b3d042f20d4cd1@o4504239195750400.ingest.sentry.io/4504239199027202",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
