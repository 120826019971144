import {useLoaderData, redirect} from "react-router-dom";
import {useContext} from "react";
import UserContext from "../UserContext";

export async function loader(user) {
    if (!user.isLoggedIn) return redirect("/logowanie");
    const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/auth/account`, {
        headers: {
            "Authorization": user.token ? `Bearer ${user.token}` : "none"
        }
    });
    if (response.status !== 200) {
        throw Error(`Getting profile has failed with ${response.status} code, body: ${await response.text()}`);
    }
    return await response.json()
}

export default function Profile() {
    const profile = useLoaderData();
    const user = useContext(UserContext);
    
    const logout = () => {
        if (window.confirm("Czy na pewno chcesz się wylogować? Pamiętaj by zapisać userId aby móc zalogować się ponownie.")) {
            user.logout();
        }
    }
    
    return (
        <>
            <h2>Profil</h2>
            <p>Witaj {profile.nickname}, merry christmas and a hacky new year!</p>
            <p>Twoje UserId: {profile.userId}</p>
            <p>Masz {profile.points} punktów!</p>
            
            <button onClick={logout}>Wyloguj się</button>
        </>
    )
}