import {useContext, useState} from "react";
import Loader from "../../components/Loader";
import FromInput from "../../components/FormInput";
import UserContext from "../../account/UserContext";

export default function SolveAssignmentForm({assignment}) {
    const user = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);    
    const [flag, setFlag] = useState("");    
    const [solvedAssignmentDetails, setSolvedAssignmentDetails] = useState("");    
    const [errorDetails, setErrorDetails] = useState([]);
    
    const submitFlag = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorDetails([]);
        const headers = {
            'content-type': 'application/json'
        };
        if (user.token) {
            headers["Authorization"] = `Bearer ${user.token}`;
        }
        const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/assignment/${assignment.id}/flag`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({flag})
        });
        if (response.status === 400) {
            const errorDetails = (await response.json()).errors;
            setErrorDetails(errorDetails);
        } else if (response.status !== 200) {
            setErrorDetails(`Błąd ${response.status}`);
        } else {
            const responseBody = await response.json();
            setSolvedAssignmentDetails(responseBody);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            {!isLoading && !solvedAssignmentDetails && <>
                <p className="error">{!Array.isArray(errorDetails) && errorDetails}</p>
                <form onSubmit={submitFlag} autoComplete="off">
                    <FromInput name="flag" label="Flaga" placeholder="HCKVNT_{...}" getter={flag} setter={setFlag} errors={errorDetails} /><br />
                    <input type="submit" value="Wyślij"/>
                </form>
            </>}
            {solvedAssignmentDetails && <>
                <h3>Flaga: <code>{flag}</code></h3>
                {solvedAssignmentDetails.pointsGained > 0 && `Otrzymujesz ${solvedAssignmentDetails.pointsGained} punktów!`}
                {solvedAssignmentDetails.pointsGained === 0 && ""}
                {solvedAssignmentDetails.pointsGained === null && "Rozwiązujesz zadania anonimowo, zaloguj się aby otrzymać punkty!"}
                
                <p>Twoja nagroda: <a href={solvedAssignmentDetails.reward} target="__blank">{solvedAssignmentDetails.reward}</a></p>
            </>}
        </>
    )
}