import {useContext, useEffect, useState} from "react";
import Loader from "../../components/Loader";
import FromInput from "../../components/FormInput";
import UserContext from "../UserContext";
import {NavLink, useNavigate} from "react-router-dom";

function Login() {
    const user = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [errorDetails, setErrorDetails] = useState([]);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (shouldRedirect || user.isLoggedIn){
            return navigate("/profil");
        }
    }, [shouldRedirect, user, navigate]);

    const login = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrorDetails([]);
        const response = await fetch(`${process.env.REACT_APP_HACKVENT_API}/api/auth/login`, {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                userId: userId
            })
        });
        if (response.status === 400) {
            const errorDetails = (await response.json()).errors;
            setErrorDetails(errorDetails);
            setIsLoading(false);
        } else if (response.status !== 200) {
            setErrorDetails(`Błąd ${response.status}`);
            setIsLoading(false);
        } else {
            const responseBody = await response.json();
            user.login(responseBody.nickname, responseBody.token);
            setShouldRedirect(true);
        }
    };

    return (
        <>
            <h2>Logowanie</h2>
            <Loader isLoading={isLoading}/>
            {!isLoading && <>
                <p className="error">{!Array.isArray(errorDetails) && errorDetails}</p>
                <form onSubmit={login}>
                    <FromInput name="userId" label="UserId" placeholder="52E358CA-2C6F-4EA5-9EC2-AD373BEE4FD4"
                               getter={userId}
                               setter={setUserId} errors={errorDetails}/><br/>
                    <input type="submit" value="Zaloguj się"/>
                </form>
                <p>Nie masz konta? <NavLink to={"/rejestracja"}>Zarejestruj się</NavLink></p>
            </>}
        </>
    );
}

export default Login;
