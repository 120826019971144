export default function Home() {
    return (
        <>
            <h2>O co chodzi?</h2>
            <p>To już czwarty rok z rzędu w którym przygotowanie fabryki świętego Mikołaja jest na Twojej głowie. W tym
                roku, odrywając właśnie kartkę z kalendarza, która zwiastuje najtrudniejszy miesiąc dla całego zakładu
                pracy, dowiadujesz się od świętego Mikołaja, że bierze urlop na najbliższe 24 dni i zamyka się w swoim
                gabinecie aby przebranżowić się na mikołajowego programistę. Wykupił już wszystkie potrzebne kursy na
                Udemy i zamierza uczyć się Javy. Nie pomagają Twoje groźby i prośby, Mikołaj właśnie Ciebie wyznaczył
                jako swojego zastępcę. Masz największe doświadczenie po akcjach z poprzednich lat, wszyscy wiedzą
                że jesteś w stanie podjąć się najcięższych zadań i pomóc elfom którzy poproszą Cię o pomoc. Mikołaj
                wychodząc z Twojego pokoju rzuca jeszcze swoje standardowe “Ho ho ho, liczę na Ciebie” i zamykając drzwi
                wysyła Ci uśmiech jak z reklamy Coli. Siadasz przy biurku i czekasz, bo wiesz, że najbliższe dni w pracy
                nie będą łatwe</p>
            <h2>Zasady</h2>
            <ol>
                <li>Zadania są publikowane od 1 do 24 grudnia, codziennie - o północy</li>
                <li>Szukaj flag oznaczonych HCKVNT_{"{twoja_odpowiedź}"}, jeśli w zadaniach nie było możliwości wpisać jej w ten sposób, flaga jest wtedy jednoznacznie oznaczona</li>
                <li>
                    Zadania są dostępne bez logowania ale tylko jako zalogowany użytkownik otrzymujesz punkty i możesz
                    trafić do rankingu
                </li>
                <li>
                    Za rozwiązanie zadania w dniu opublikowania otrzymasz 10 punktów, w następnych dniach 5 punktów.
                </li>
                <li>Nie używaj automatycznych skanerów podatności - wszystkie zadania da się rozwiązać bez tego typu
                    narzędzi a ryzykujesz popsucie platformy dla siebie i innych uczestników
                </li>
            </ol>
            <h2>Autorzy</h2>
            <p>Hackvent przygotowali <a href="https://www.instagram.com/pl.coding.girl/" target="_blank"
                                        rel="noreferrer">pl.coding.girl</a> i <a href="https://github.com/mwpro"
                                                                                 target="_blank"
                                                                                 rel="noreferrer">mwpro</a>.</p>
            <p>Projekt jest efektem naszej pracy po godzinach, jeżeli dobrze bawiłeś się przy rozwiązywaniu zagadek
                możesz rozważyć <a href="https://buycoffee.to/pl.coding.girl" target="_blank" rel="noreferrer">kupienie
                    nam kawy</a>.</p>
            <h2>Poprzednia edycja</h2>
            <p><a href="https://2021.hackvent.pl" target="_blank" rel="noreferrer">2021.hackvent.pl</a></p>
            <h2>Kontakt</h2>
            <p>Skontaktuj się z nami przez social media lub napisz na hello@hackvent.pl</p>
            <h2>Muzyka</h2>
            <ul>
                <li>Jingle Bells 8Bit" by Tim Beek (timbeek.com)</li>
                <li>silent night by Nullsleep</li>
                <li>Mykola Leontovych - Shchedryk *Carol of the bells* (8 Bits Version) by Classical 8 Bit</li>
            </ul>
        </>
    );
}
